/* Importing Bootstrap SCSS files */
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";

/* Importing Datepicker SCSS file */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

/* Importing NgSelect CSS file */
@import "~@ng-select/ng-select/themes/default.theme.css";

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0rem rgba($danger, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0.25rem rgba($danger, 0.2);
  }
}

kbd {
  background-color: $gray-600;
}

.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}

.form-group {
  > label {
    font-size: $font-size-sm;
  }
}

.form-control {
  &.irresizable {
    resize: none;
  }
}

.form-check-input {
  &:focus {
    box-shadow: $input-focus-box-shadow;
  }

  &:disabled {
    background-color: #eee;
    cursor: not-allowed;
  }
}

.custom-form-select {
  .ng-select-container {
    appearance: none;
    background-color: #fff;
    background-image: escape-svg($custom-select-indicator);
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    border: none;
    border-radius: 0.25rem;
    box-shadow: 0 0.0625rem 0.25rem rgba($black, 0.25);
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    padding: 0.5rem 0.75rem;
    height: auto;
    width: 100%;
    -webkit-appearance: none;
    -moz-padding-start: calc(.75rem - 3px);

    .ng-clear-wrapper {
      margin-right: 1rem;
    }

    .ng-value-container {
      padding-left: 0;
      height: 100%;

      .ng-value {
        white-space: normal !important;
        word-break: break-all;
        height: 100%;
      }

      .ng-input {
        top: 0 !important;
        height: 100%;

        input {
          border: none;
          height: 100%;
          width: 100%;
        }
      }
    }

    .ng-arrow-wrapper {
      display: none;
    }

    &:hover {
      box-shadow: 0 0.0625rem 0.25rem rgba($black, 0.25);
    }
  }

  &.ng-select-opened {
    .ng-select-container,
    .ng-select-container:hover {
      box-shadow: 0 0 0 0.125rem rgba($primary, 0.75);
    }
  }

  &.custom-form-select-lg {
    .ng-select-container {
      font-size: 1.25rem;
      min-height: calc(1.5em + 1rem);
    }
  }
}

.input-group {
  > .input-group-prepend {
    > .input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 100%;
    }
  }

  .input-group-text:first-child {
    border-right: 1px solid #d2d6da;
  }
}

.input-group > :last-child:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback):not(.input-group-prepend):not(.input-group-append):not(:focus) {
  border-right: 1px solid #d2d6da !important;
}

.input-group > :first-child:not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback):not(.input-group-prepend):not(.input-group-append):not(:focus) {
  border-left: 1px solid #d2d6da !important;
}

.dropdown {
  cursor: pointer;

  .dropdown-hover:hover > .dropdown-menu:before,
  .dropdown-menu.show:before {
    top: -18px;
  }
}

.btn {
  margin-bottom: 0;

  &.btn-custom-sm {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.875rem 2rem !important;
}

.card {
  &.fancy-card {
    border: 1px solid #d2d6da;
    border-radius: 1rem;
    box-shadow: none;
    width: 100%;

    .card-footer {
      background-color: #f7f7f7;
      border-top: 1px solid #d2d6da;
    }
  }
}

.sidenav-header {
  height: auto !important;
}

.navbar-vertical .navbar-brand > img,
.navbar-vertical .navbar-brand-img {
  max-height: fit-content
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  height: auto;
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  justify-content: flex-start;
}

.table,
.table.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #344767 !important;
}

.table > :not(caption) > * > * {
  padding: 0.75rem 1.5rem;
}

.table thead th {
  text-transform: none;
}

.table tbody td {
  vertical-align: top;
}

.table {
  &.table-sm {
    th,
    td {
      padding: 0.5rem 0.75rem;
    }
  }

  &:not(.table-bordered) {
    tbody {
      tr:last-child {
        td,
        th {
          border-width: 0;
        }
      }
    }
  }

  th,
  td {
    &.sticked {
      background-color: #fff;
      position: sticky;
      right: 0;
    }
  }
}

.box {
  border: 1px solid #eee;
  border-radius: 1rem;
  padding: 1rem;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.sidenav {
  z-index: 999 !important;
}

.box-canvas {
  aspect-ratio: 1 / 2;
  width: 30rem;
}

.pagination {
  user-select: none;
  margin-bottom: 0;

  .page-item.disabled {
    cursor: not-allowed;

    .page-link {
      background-color: #dee2e6;
      cursor: not-allowed;
    }
  }
}

.badge-group {
  > .badge {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 0.45rem;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.45rem;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0.45rem;
    }
  }
}

.text-yellow {
  color: $yellow;
}

.notification {
  color: $dark;
  text-align: left;
}

.notif-icon {
  align-items: center;
  aspect-ratio: 1;
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  &.new-notif {
    &::after {
      background-color: $danger;
      border-radius: 0.5rem;
      content: '';
      display: inline-block;
      position: absolute;
      bottom: -0.125rem;
      left: -0.125rem;
      height: 0.5rem;
      width: 0.5rem;
      animation: ripple 1s linear infinite;
    }
  }
}

.nav-tab-content {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  overflow: auto;
  padding: 1rem;
  max-height: 50vh;
}

@for $i from 1 through 10 {
  .z-#{$i} {
    z-index: $i;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .dropdown:not(.dropdown-hover) .dropdown-menu {
    margin-top: 1.5rem !important;
  }

}

@import "./styles/custom";

html, body { height: 100%; }
body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  overflow-x: hidden;
  margin: 0;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

$custom-bg-emerald: #2dce89;
$design-opacity: 0.75;

label {
  &.required {
    &::after {
      color: $success;
      content: '*';
      display: inline-block;
      font-size: 1rem;
      margin-left: 0.25rem;
      line-height: 1rem;
    }
  }
}

.text-translucent {
  color: rgba($black, 0.5);
}

.display-text {
  &-lg {
    color: #000 !important;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  &-xl {
    color: #000 !important;
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

.display-title {
  color: #000 !important;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  line-height: 2.5rem;
}

.display-subtitle {
  color: #000 !important;
  font-size: 2rem;
  margin-bottom: 1rem;
  line-height: 2rem;
}

.design-login {
  &::before {
    background-color: rgba($custom-bg-emerald, $design-opacity);
    content: '';
    display: inline-block;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10vw;
  }

  &::after {
    border-top: 50vh solid rgba($custom-bg-emerald, $design-opacity);
    border-bottom: 50vh solid transparent;
    border-left: 25vw solid rgba($custom-bg-emerald, $design-opacity);
    border-right: 25vw solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 10vw;
    bottom: 0;
    width: 50vw;
  }

  * {
    z-index: 1;
  }
}

.design-time-clock {
  background: url('/assets/svgs/blurry-gradient.svg');
  background-size: cover;
  background-position: center;

  .display-space {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    width: 100%;
  }

  .workspace {
    overflow: auto;
    position: relative;
    min-height: 100vh;
    width: 100%;
  }

  .login-block {
    width: 25rem;
    max-width: 100%;
  }
}

.lh-1 {
  line-height: 1rem;
}

.lh-1-25 {
  line-height: 1.25rem;
}

.lh-1-5 {
  line-height: 1.5rem;
}

.message-list {
  background-color: $white;

  .message-list-item {
    color: $dark !important;

    &:not(.unclickable) {
      cursor: pointer;
    }

    &:not(.no-hover):hover {
      background-color: $light;
    }

    .message-list-item-title {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.25rem;
    }

    .message-list-item-subtitle {
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }
}

.group-box {
  background-color: #f7f7f7;
  border: 1px solid #344767;
  border-radius: 0.5rem;
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;

  &::before,
  .group-box-title {
    align-items: center;
    background-color: #f7f7f7;
    color: #344767;
    display: inline-flex;
    font-size: 0.875rem;
    font-weight: 700;
    position: absolute;
    top: -1px;
    padding: 0 0.5rem;
    line-height: 2px;
    height: 2px;
  }

  &::before {
    content: attr(data-title);
  }
}

.file-over {
  position: relative;

  &::after {
    align-items: center;
    background-color: rgba($custom-bg-emerald, 0.5);
    border: 0.25rem dashed rgba($black, 0.5);
    color: rgba($black, 0.5);
    content: 'Drop file(s) here...';
    display: inline-flex;
    font-size: 2rem;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.message-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  height: 100%;

  .message-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;

    &.right {
      justify-content: flex-end;

      .message-box-content {
        text-align: right;

        .message-box-content-inner {
          background-color: $custom-bg-emerald;
        }

        .message-box-content-time {
          text-align: right;
        }
      }
    }

    .message-box-content {
      text-align: left;
      max-width: 50%;

      .message-box-content-inner {
        background-color: $info;
        border-radius: 1rem;
        color: $white;
        display: inline-block;
        text-align: left;
        padding: 1rem;
        width: auto;
      }

      .message-box-content-time {
        font-size: 0.75rem;
        margin-top: 0.25rem;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        text-align: left;
      }
    }
  }
}

.no-pointer-events {
  pointer-events: none;
}

.site-logo {
  height: 7rem;
  max-width: 70%;
}

.version-box {
  display: flex;
  flex-direction: row;
  padding: 1rem;

  > .version-box-item {
    flex: 1;
    text-align: center;

    .version-number {
      font-size: 1rem;
      font-weight: bold;
    }

    .version-of {
      font-size: 0.7rem;
    }
  }
}

.etch-box {
  align-items: center;
  border-radius: 2.5rem;
  display: inline-flex;
  flex-direction: row;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;

  .etch-box-icon {
    align-items: center;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 2rem;
    width: 2rem;
  }

  .etch-box-label {
    font-size: 1rem;
    font-weight: 600;
  }
}

.detailer {
  background-color: #fff;
  padding: 1rem;
  line-height: 1rem;

  &:nth-child(even) {
    background-color: #f8f9fa;
  }

  .detailer-label {
    color: #6c757d;
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .detailer-text {
    color: #000;
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .detailer-subtext {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.calendar {
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;

  .calendar-header {
    .calendar-label {
      background-color: white;
      border: 1px solid #eee;
      color: #344767;
      display: inline-block;
      font-size: 0.75rem;
      font-weight: 600;
      position: relative;
      text-align: center;
      padding: 0.25rem;
      min-width: calc(100% / 7);
      max-width: calc(100% / 7);
    }
  }

  .calendar-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .calendar-mark {
      align-items: center;
      border-radius: 0.5rem;
      display: inline-flex;
      flex-direction: row;
      font-size: 0.8rem;
      font-weight: 600;
      justify-content: center;
      overflow: hidden;
      padding: 0;
      height: 0.5rem;
      width: 0.5rem;
    }

    .calendar-spacer {
      aspect-ratio: 1;
      background-color: white;
      border: 1px solid #eee;
      display: inline-block;
      position: relative;
      padding: 0;
      min-width: calc(100% / 7);
      max-width: calc(100% / 7);
    }

    .calendar-day {
      aspect-ratio: 1;
      align-items: flex-end;
      background-color: white;
      border: 1px solid #eee;
      display: inline-flex;
      justify-content: flex-end;
      position: relative;
      padding: 0.25rem;
      min-width: calc(100% / 7);
      max-width: calc(100% / 7);

      &:hover {
        background-color: #f7f7f7;
      }

      .calendar-day-title {
        align-items: center;
        color: #344767;
        content: attr(data-day);
        display: inline-flex;
        font-size: 0.5rem;
        font-weight: 600;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 0.125rem;
        left: 0.125rem;
        line-height: 0.75rem;
        height: 1rem;
        width: 1rem;
      }

      .calendar-day-content {
        display: inline-flex;
        gap: 0.125rem;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: flex-end;
      }
    }
  }
}

.panel-plain {
  border: 1px solid #eee;
  border-radius: 0.25rem;
  box-shadow: none;
  overflow: hidden;
  margin-bottom: 0.25rem;

  &.panel-themed {
    > .panel-heading {
      background-color: #f7f7f7;
      border-bottom: 1px solid #eee;
    }
  }

  > .panel-heading,
  .panel-body {
    border-radius: 0;
    padding: 0.5rem 0.75rem;
  }
}

.profile-img {
  height: 32px;
  width: 32px;

  &.profile-img-lg {
    height: 48px;
    width: 48px;
  }

  &.profile-img-xl {
    height: 64px;
    width: 64px;
  }

  &.profile-img-xxl {
    height: 100px;
    width: 100px;
  }
}

.switch-account {
  background-color: $white;
  color: $custom-bg-emerald;
  border: none;
  border-radius: 32px;
  position: absolute;
  bottom: -12px;
  right: -12px;
  height: 32px;
  width: 32px;
}

.clip-text {
  background-clip: text;
  color: transparent;
}

.or-divider {
  align-items: center;
  color: $gray-600;
  display: flex;
  text-align: center;
  line-height: 1rem;

  &::before,
  &::after {
    border-top: 1px dashed $gray-600;
    content: '';
    display: inline-block;
    flex: 1;
    margin: 0 0.5rem;
  }
}

.tutorial {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .tutorial-cut-out {
    border-radius: 5rem;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75);
    display: inline-block;
    position: absolute;
    transform: scale(1);
    top: 10%;
    left: 10%;
    margin-top: -2.5rem;
    margin-left: -2.5rem;
    height: 5rem;
    width: 10rem;
  }
}

.monitoring-carousel {
  .carousel-control {
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .monitoring-carousel-title {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    -webkit-text-stroke: #000;
    -webkit-text-stroke-width: 1px;
  }
}

.falling-symbols {
  $total: 100;
  border-radius: 50%;
  font-size: 20px;
  pointer-events: none;
  position: absolute;
  z-index: 99999;
  top: 0;
  height: 20px;
  width: 20px;

  &.white {
    color: #fff;
  }

  &.red {
    color: #f00;
  }

  @for $i from 1 through $total {
    $random-x: random(1000000) * 0.0001vw;
    $random-offset: random_range(-100000, 100000) * 0.0001vw;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 95vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(10, 30) * 1s;
    $fall-delay: random(30) * -1s;

    &:nth-child(#{$i}) {
      opacity: random_range(50, 100) * 0.01;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 95vh) scale($random-scale);
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .design-login {
    &::after {
      border-left: 10vw solid rgba($custom-bg-emerald, $design-opacity);
      border-right: 10vw solid transparent;
      width: 20vw;
    }
  }

  .display-text {
    &-lg {
      font-size: 2rem;
      line-height: 2rem;
    }

    &-xl {
      font-size: 3rem;
      line-height: 3rem;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .calendar {
    .calendar-header {
      .calendar-label {
        font-size: 1rem;
      }
    }

    .calendar-body {
      .calendar-mark {
        border-radius: 1rem;
        height: 1rem;
        width: 1rem;
      }

      .calendar-day {
        .calendar-day-title {
          font-size: 0.75rem;
          top: 0.25rem;
          left: 0.25rem;
        }
      }
    }
  }

  .switch-account {
    bottom: 0;
    right: 0;
  }

  .monitoring-carousel {
    .carousel-control {
      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    .monitoring-carousel-title {
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
      -webkit-text-stroke: #000;
      -webkit-text-stroke-width: 1px;
    }
  }

  .design-time-clock {
    .display-space {
      flex: 3;
    }

    .workspace {
      border-radius: 0;
      flex: 2;
      height: 100vh;
    }
  }
}
